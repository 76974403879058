export const PAGE = {
  en: {
    title: 'Contact Us',
    button: 'Submit',
    img: 'contact.png',
  },
  ar: {
    title: 'اتصل بنا',
    button: 'ارسال',
    img: 'contact.png',
  },
}
export const NAME = {
  en: {
    label: 'Name',
    placeholder: 'Enter Full Name',
  },
  ar: {
    label: 'الاسم',
    placeholder: 'اكتب اسمك الكامل',
  },
}

export const MOBILENUMBER = {
  en: {
    label: 'Mobile Number',
    placeholder: 'Enter Mobile Number',
  },
  ar: {
    label: 'رقم الهاتف المحمول',
    placeholder: 'اكتب رقم هاتفك المحمول',
  },
}
export const EMAIL = {
  en: {
    label: 'Email',
    placeholder: 'Enter Email Address',
  },
  ar: {
    label: 'البريد الإلكتروني',
    placeholder: 'اكتب عنوان بريدك الإلكتروني',
  },
}
export const DATETIMEPICKER = {
  en: {
    label: 'Preferred Time for Call ',
    placeholder: 'Pick a convenient date and time',
  },
  ar: {
    label: 'Altaree5 wal wakt al mofadalin ladaik',
    placeholder: 'e5tar taree5 wawakt almonasbin',
  },
}
export const TOPICS = {
  en: {
    label: 'Topics',
    placeholder: 'Please Select a Topic',
    options: [{text: 'Schedule a Call', id: 'schedule-a-call'}, {text: 'General Inquiry', id: 'general-inquiry'}, {text: 'Technical Support', id: 'technical-support'}],
  },
  ar: {
    label: 'الغرض',
    placeholder: 'الرجاء اختيار الموضوع',
    options: ['حدد معاد مكالمة تجريبية', 'استفسار عام', 'دعم فني'],
  },
}
export const MESSAGE = {
  en: {
    label: 'Message',
    placeholder: 'Let Us Know How Can We Help You',
  },
  ar: {
    label: 'ملاحظتك',
    placeholder: 'كيف يمكننا مساعدتك',
  },
}
