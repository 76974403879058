import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import {
  PAGE,
  NAME,
  EMAIL,
  TOPICS,
  MESSAGE,
  MOBILENUMBER,
  DATETIMEPICKER,
} from '../CMS/Contact'

import {
  FlexContainer,
  Container,
  Input,
  Select,
  Button,
  FormRow,
  FilledCircle,
  OutlineCircle,
} from '../elements'

import { getImage } from '../imageContainers'
import Gear from './gear'

const bounce = keyframes`
    0%   { transform: scale(1,1)      translateY(0); }
    10%  { transform: scale(1.1,.9)   translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-70px); }
    50%  { transform: scale(1.05,.95) translateY(0); }
    57%  { transform: scale(1,1)      translateY(-7px); }
    64%  { transform: scale(1,1)      translateY(0); }
    100% { transform: scale(1,1)      translateY(0); }
`
let tzoffset = new Date().getTimezoneOffset() * 60000 //offset in milliseconds
let tomorrow = new Date(Date.now() - tzoffset)
tomorrow.setDate(new Date().getDate() + 1)
tomorrow.setHours(14, 0, 0)
let minDate = `${tomorrow.toISOString().slice(0, -8)}`

const ContactUs = ({ className, lang, location }) => {
  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [message, setmessage] = useState('')
  const [topics, settopics] = useState(
    location?.search?.split('issue=')[1] ?? ''
  )
  const [mobileNumber, setmobileNumber] = useState('+20')
  const [dateTime, setdateTime] = useState(minDate)

  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [topicsError, setTopicsError] = useState('')
  const [mobileNumberError, setMobileNumberError] = useState('')
  const [dateTimeError, setDateTimeError] = useState('')

  const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

  const submitHandler = () => {
    if (!name) {
      setNameError('Required')
    }
    if (!mobileNumber || mobileNumber === '+20') {
      setMobileNumberError('Required')
    }
    if (mobileNumber.substring(0, 3) !== '+20' && mobileNumber.length > 3) {
      setMobileNumberError('Mobile Number must start with +20')
    }
    if (mobileNumber.length !== 13) {
      setMobileNumberError(
        'Mobile Number must be 10 digits (regardless of country code +20)'
      )
    }
    if (!email) {
      setEmailError('Required')
    }
    if (!dateTime) {
      setDateTimeError('Required')
    }
    if (!topics) {
      setTopicsError('Required')
    }
  }

  const nameHandler = e => {
    setname(e.target.value)
    if (e.target.value) setNameError('')
  }

  const mobileNumberHandler = e => {
    setmobileNumber(e.target.value)
    if (!e.target.value || e.target.value === '+20') {
      setMobileNumberError('Required')
    } else if (e.target.value.substring(0, 3) !== '+20') {
      setMobileNumberError('Mobile Number must start with +20')
    } else if (e.target.value.length !== 13) {
      setMobileNumberError(
        'Mobile Number must be 10 digits (regardless of country code +20)'
      )
    } else {
      setMobileNumberError('')
    }
  }

  const emailHandler = e => {
    setemail(e.target.value)
    if (!emailRegex.test(e.target.value)) {
      setEmailError('Enter a valid email')
    } else {
      setEmailError('')
    }
  }

  const dateTimeHandler = e => {
    setdateTime(e.target.value)
    setDateTimeError('')
  }

  const topicsHandler = e => {
    settopics(e.target.value)
    if (e.target.value) setTopicsError('')
  }

  const textHandler = e => {
    setmessage(e.target.value)
  }

  const submit = e => {
    e.preventDefault()
    if (
      !(
        nameError ||
        mobileNumberError ||
        emailError ||
        dateTimeError ||
        topicsError
      ) &&
      name &&
      email &&
      topics &&
      mobileNumber &&
      dateTime
    ) {
      const form = document.forms['contact-form']
      const scriptURL =
        'https://script.google.com/macros/s/AKfycbxZPTV8BptZqJGStgK4I6BQJKD3oCYYUpED5_7rR9eNNxcFycXn/exec'

      fetch(scriptURL, { method: 'POST', body: new FormData(form) })
      setname('')
      setmobileNumber('+20')
      setemail('')
      setdateTime(minDate)
      settopics('')
      setmessage('')

      setNameError('')
      setMobileNumberError('')
      setEmailError('')
      setDateTimeError('')
      setTopicsError('')
    }
  }

  return (
    <Container className={className}>
      <OutlineCircle
        top="-30px"
        right="1.4rem"
        size="142"
        small_top="3rem"
        small_left="0rem"
        duration="30s"
        delay="0.5s"
        thickness="3"
        innerposition="1.6px"
      />
      <h1 className="ContactpageTitle" dir={lang === 'ar' ? 'rtl' : null}>
        {PAGE[lang].title}
        <FilledCircle
          top="-10px"
          left={lang === 'ar' ? 'unset' : '6.4rem'}
          right={lang === 'ar' ? '6.4rem' : 'unset'}
          size="70px"
          duration="5s"
        />
      </h1>
      <FlexContainer
        width="100%"
        direction="column"
        alignItems="flex-start"
        justifyContent="space-around"
        margin="20px auto"
        flexWrap="wrap"
      >
        <form
          name="contact-form"
          onSubmit={submit}
          method="POST"
          className="gform"
          action="mailto:mamoanwar@hotmail.com"
        >
          <FormRow dir={lang === 'ar' ? 'rtl' : null}>
            <label htmlFor="name">{NAME[lang].label}</label>
            <Input
              name="name"
              type="text"
              value={name}
              className={nameError && !name && 'error'}
              onChange={nameHandler}
              id="name"
              placeholder={nameError ? nameError : NAME[lang].placeholder}
              required
            />
            <OutlineCircle
              delay="0.4s"
              fill="alternate"
              reverse="1"
              duration="30s"
              top="0px"
              right="-1rem"
              size="47"
              thickness="3"
              innerposition="1.5px"
            />
          </FormRow>

          <FormRow dir={lang === 'ar' ? 'rtl' : null}>
            <label htmlFor="mobileNumber">{MOBILENUMBER[lang].label}</label>
            <Input
              name="mobileNumber"
              type="text"
              value={mobileNumber}
              className={mobileNumberError && 'error'}
              onChange={mobileNumberHandler}
              id="mobileNumber"
              placeholder={
                mobileNumberError
                  ? mobileNumberError
                  : MOBILENUMBER[lang].placeholder
              }
              required
            />
            <OutlineCircle
              delay="0.4s"
              fill="alternate"
              reverse="1"
              duration="30s"
              top="0px"
              right="-1rem"
              size="47"
              thickness="3"
              innerposition="1.5px"
            />
          </FormRow>

          <FormRow dir={lang === 'ar' ? 'rtl' : null}>
            <label htmlFor="email">{EMAIL[lang].label}</label>
            <Input
              name="email"
              type="email"
              className={
                emailError && (!email || !emailRegex.test(email)) && 'error'
              }
              value={email}
              onChange={emailHandler}
              id="email"
              placeholder={emailError ? emailError : EMAIL[lang].placeholder}
              required
            />
            <OutlineCircle
              delay="0"
              reverse="1"
              duration="25s"
              top="1rem"
              left="-6.5rem"
              size="112"
              small_left="-3rem"
              small_top="-1rem"
              thickness="3"
              innerposition="1.5px"
            />
          </FormRow>
          <FormRow dir={lang === 'ar' ? 'rtl' : null}>
            <label htmlFor="name">{DATETIMEPICKER[lang].label}</label>
            <Input
              name="dateTime"
              type="datetime-local"
              min={minDate}
              value={dateTime}
              className={dateTimeError && !dateTime && 'error'}
              onChange={dateTimeHandler}
              id="dateTime"
              placeholder={
                dateTimeError ? dateTimeError : DATETIMEPICKER[lang].placeholder
              }
              required
            />
          </FormRow>

          <FormRow dir={lang === 'ar' ? 'rtl' : null}>
            <label htmlFor="topic">{TOPICS[lang].label}</label>
            <Select
              name="topic"
              required
              value={topics}
              onChange={topicsHandler}
              id="topic"
              className={topics ? '' : topicsError ? 'error' : 'placeholder'}
            >
              <option value="" disabled>
                {topicsError ? topicsError : TOPICS[lang].placeholder}
              </option>
              {TOPICS[lang].options.map(o => (
                <option key={o.id} value={o.id} className="option">
                  {o.text}
                </option>
              ))}
            </Select>
          </FormRow>

          <FormRow dir={lang === 'ar' ? 'rtl' : null}>
            <Gear
              size="50"
              top="-20%"
              left="-5rem"
              small_top=""
              small_left="-2rem"
            />
            <Gear
              size="30"
              top="-2.5rem"
              left="-8rem"
              small_top="-3rem"
              small_left="2rem"
            />
            <label htmlFor="Message">{MESSAGE[lang].label}</label>
            <FlexContainer
              className="textarea"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <textarea
                aria-label="Message"
                name="message"
                value={message}
                onChange={textHandler}
                id="Message"
                placeholder={MESSAGE[lang].placeholder}
                rows="5"
              ></textarea>
              <div className="contact-block">
                <div className="meliorSubmit">{getImage(PAGE[lang].img)}</div>
                <Button
                  className="submitButton"
                  onClick={submitHandler}
                  type="submit"
                >
                  {PAGE[lang].button}
                </Button>
              </div>
            </FlexContainer>
          </FormRow>
        </form>
      </FlexContainer>
    </Container>
  )
}

export default styled(ContactUs)`
  padding: 5px 0;
  form {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .submitButton {
    width: 90%;
    margin-bottom: 0;
  }
  Button {
    &:hover {
      background: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.yellowMelior};
      transition: 0.2s;
      box-shadow: inset 0 0 0 2px ${props => props.theme.colors.yellowMelior};
    }
  }
  .contact-block {
    width: 30%;
  }
  .textarea {
    width: 80%;
  }
  .meliorSubmit {
    max-width: 100px;
    margin: 0 auto;
  }
  .contact-block:hover .meliorSubmit {
    animation-duration: 1.8s;
    animation-name: ${bounce};
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  }
  .ContactpageTitle {
    margin: 40px 0;
    position: relative;
    &::before {
      content: '+';
      font-size: 1.5rem;
      font-weight: bold;
      position: absolute;
      top: -1.2rem;
      left: ${props => (props.lang === 'ar' ? 'unset' : '12rem')};
      right: ${props => (props.lang === 'ar' ? '8rem' : 'unset')};
    }
    &::after {
      content: 'o';
      font-size: 1rem;
      font-weight: bold;
      position: absolute;
      top: 0.2rem;
      left: ${props => (props.lang === 'ar' ? 'unset' : '13rem')};
      right: ${props => (props.lang === 'ar' ? '9rem' : 'unset')};
    }
  }
  @media (max-width: 580px) {
    .contact-block {
      margin: 40px 0;
      width: 100%;
    }
    .textarea {
      width: 90%;
    }
  }
`
